(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
'use strict';

require('./modules/lazy-bg');

require('./modules/modals');

require('./modules/forms');

require('./modules/masks');

require('./modules/spinner');

require('./modules/scroll');

require('./modules/menus');

require('./modules/tabs');

require('./modules/galleries');

require('./modules/sliders');

require('./modules/spoilers');

require('./modules/vitrines');

require('./modules/filters');

require('./modules/catalogue');

require('./modules/compare');

require('./modules/order');

require('./modules/share');

require('./modules/card-choose-offer');

},{"./modules/card-choose-offer":3,"./modules/catalogue":4,"./modules/compare":5,"./modules/filters":6,"./modules/forms":7,"./modules/galleries":8,"./modules/lazy-bg":11,"./modules/masks":12,"./modules/menus":13,"./modules/modals":14,"./modules/order":15,"./modules/scroll":16,"./modules/share":17,"./modules/sliders":18,"./modules/spinner":19,"./modules/spoilers":20,"./modules/tabs":21,"./modules/vitrines":22}],2:[function(require,module,exports){
module.exports={
  "mobile": {
    "landscape": 812,
    "portrait": 700,
    "small": 480
  },
  "tablet": {
    "landscape": 1140,
    "portrait": 768
  },
  "notebook": {
    "small": 1300,
    "normal": 1440
  }
}

},{}],3:[function(require,module,exports){
'use strict';

var _PriceComponent = require('./vue-components/PriceComponent');

var _PriceComponent2 = _interopRequireDefault(_PriceComponent);

var _OffersListComponent = require('./vue-components/OffersListComponent');

var _OffersListComponent2 = _interopRequireDefault(_OffersListComponent);

var _DumpComponent = require('./vue-components/DumpComponent');

var _DumpComponent2 = _interopRequireDefault(_DumpComponent);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

window.initPriceApp = function (element) {
  if (!$(element).length) return;

  var cardPriceApp = new Vue({
    el: element,
    components: {
      'price-component': _PriceComponent2.default,
      'offers-list-component': _OffersListComponent2.default,
      'dump-component': _DumpComponent2.default
    },
    data: {
      offer: {},
      targetButton: ''
    },
    mounted: function mounted() {
      this.$nextTick(function () {
        this.offer = JSON.parse(this.$el.getAttribute('data-default-offer'));
        this.targetButton = this.$el.getAttribute('data-target-button');
      });
    },
    watch: {
      //Обнавляем данные на кнопке купить
      offer: function offer(val, oldVal) {

        $(element + ' ' + this.targetButton).each(function () {
          var data = $(this).data();
          $.extend(data, val.basketData);
          if (data['formData']) data['formData'].articul = val.articul;else $.extend(data, { 'formData': { 'articul': val.articul } });
          $(this).data(data);
        });

        var articul = $(element).closest('#product-content').find('.js-articul');

        if (val.articul == '') articul.html('&nbsp;');else articul.text('Артикул ' + val.articul);

        $(element + ' .dump-button').hide();

        if (val.price == 0 || val.dump == 0) {
          $(element + ' #js-show-similar-tab').show();
          $(element + ' .js-call-manager').show();
        } else {
          $(element + ' .product-card__price').html(val.price.toLocaleString() + ' <span class="currency"><span>₽</span></span>');
          $(element + ' #js-show-similar-tab').hide();
          $(element + ' .js-call-manager').hide();
          $(element + ' .dump-' + val.dump).show();
        }
      }
    }
  });
}; /**
    * Подключение в common.js
    *   import './modules/card-choose-offer';
    *
    * Пример подключения в шаблоне
    * <div id="js-card-price-app" class="product-card__controls-column" data-default-offer='<?=$model->getBasketObjectJson()?>' data-target-button=".js-cart-basket-button">
   
    * <offers-list-component :offers-list='<?=json_encode($offers)?>' inline-template>
    *  <div>
    *    <label class="item js-radio" v-bind:class="{'is-checked': isActive(offer.id)}" v-bind:data-size="offer.text"
    *           v-for="offer in offersList" @click="$root.offer = offer">{{offer.text}}</label>
    *  </div>
    * </offers-list-component>
    *
    * <price-component :price="this.offer.price" :old-price="this.offer.oldPrice" inline-template>
    * <div class="product-card__price-group">
    *   <div class="product-card__old-price" v-if="oldPrice">
    *     <span v-html="oldPriceFormatted"></span>
    *     <div class="product-card__discount" v-html="economyPercentFormatted"></div>
    *   </div>
    *   <div class="product-card__price" v-html="priceFormatted"><?php echo PriceHelper::price($model->getPrice(), ' <span class="currency"><span>₽</span></span>');?></div>
    * </div>
    * </price-component>
    *
    * </div>
    *
    */


window.initPriceApp('#js-card-price-app');

/*
          let data = $(this.targetButton).data();
          $.extend(data, val.basketData);

          //console.log(data);

          $.extend(data, {'form-data' : {'articul' : val.articul}});

          if( data['form-data'] )
          //data['form-data'].articul = val.articul;

          //console.log(data);

          $(this.targetButton).data(data);
 */

},{"./vue-components/DumpComponent":23,"./vue-components/OffersListComponent":24,"./vue-components/PriceComponent":25}],4:[function(require,module,exports){
'use strict';

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

var scrollToCatalog = false;

$('.js-filter-parts-group').click(function (e) {
  e.preventDefault();

  var $this = $(this);
  var group = $this.attr('href');
  var $group = $('.js-parts-list [data-group="' + group + '"]');

  $this.toggleClass('is-active').siblings().removeClass('is-active');
  $('.js-parts-list .is-active').removeClass('is-active');

  if ($this.hasClass('is-active')) {
    $group.addClass('is-active');
  }
});

$('.js-toggle-catalogue-text').click(function (e) {
  e.preventDefault();

  $(this).closest('.js-catalogue-text-spoiler').toggleClass('is-expanded');
});

$(document).on('click', '.js-toggle-seen-panel', function () {
  $('.js-seen-panel').toggleClass('is-expanded');
});

$('.js-toggle-brand-info, .js-toggle-technology-info').click(function () {
  var $item = $(this).closest('.item');

  $item.toggleClass('is-expanded').siblings().removeClass('is-expanded').css('marginBottom', 'auto');

  if ($item.hasClass('is-expanded')) {
    $item.css('marginBottom', $item.find('.item__content').outerHeight());
  } else {
    $item.css('marginBottom', 'auto');
  }

  $item.off('click.resizeInfo').on('click.resizeInfo', '.js-spoiler-anchor, .js-mobile-spoiler-anchor', function () {
    if ($item.hasClass('is-expanded')) {
      setTimeout(function () {
        $item.css('marginBottom', $item.find('.item__content').outerHeight());
      }, 250);
    }
  });
});

$('.js-brands-filter').each(function () {
  var $filter = $(this);
  var $brands = $('.brands-catalogue .item');
  var letters = [].concat(_toConsumableArray(new Set($brands.map(function (index, item) {
    return $(item).data('name')[0].toLowerCase();
  })))).sort(function (a, b) {
    return a.localeCompare(b);
  });
  letters = ['все'].concat(_toConsumableArray(letters));

  letters.forEach(function (item) {
    var letter = item;
    var $letter = $('<a>' + letter + '</a>');
    $filter.append($letter);
    if (letter == 'все') {
      $letter.addClass('is-active');
    }
    $letter.click(function () {
      $brands.removeClass('is-expanded').attr('style', '');
      $letter.toggleClass('is-active').siblings().removeClass('is-active');
      if (letter == 'все') {
        $brands.stop(true, true).fadeIn();
        $letter.addClass('is-active');
      } else {
        if ($letter.hasClass('is-active')) {
          $brands.stop(true, true).hide();
          $brands.filter(function (index, item) {
            return $(item).data('name')[0].toLowerCase() == letter;
          }).fadeIn();
        } else {
          $brands.stop(true, true).fadeIn();
          $filter.find('a:first').addClass('is-active');
        }
      }
    });
  });
});

$('.js-brands-technologies-filter').each(function () {
  var $filter = $(this);
  var $brands = $('.technologies-section .technologies-group');
  var letters = [].concat(_toConsumableArray(new Set($brands.map(function (index, item) {
    return $(item).data('brand')[0].toLowerCase();
  })))).sort(function (a, b) {
    return a.localeCompare(b);
  });
  letters = ['все'].concat(_toConsumableArray(letters));

  letters.forEach(function (item) {
    var letter = item;
    var $letter = $('<a>' + letter + '</a>');
    $filter.append($letter);
    if (letter == 'все') {
      $letter.addClass('is-active');
    }
    $letter.click(function () {
      $letter.toggleClass('is-active').siblings().removeClass('is-active');
      if (letter == 'все') {
        $brands.stop(true, true).fadeIn();
        $letter.addClass('is-active');
      } else {
        if ($letter.hasClass('is-active')) {
          $brands.stop(true, true).hide();
          $brands.filter(function (index, item) {
            return $(item).data('brand')[0].toLowerCase() == letter;
          }).fadeIn();
        } else {
          $brands.stop(true, true).fadeIn();
          $filter.find('a:first').addClass('is-active');
        }
      }
    });
  });
});

$(document).on('click', '.catalogue-pagination--bottom .pager a', function () {
  scrollToCatalog = true;
});

$(function () {
  $(document).on('yiiListViewUpdated lazyPicsInit', function () {

    if (scrollToCatalog === true) {
      $(window).scrollTo($('#product_list'), { duration: 200 });
      scrollToCatalog = false;
    }

    $('.js-lazy-pic').unveil(200);
  }).trigger('lazyPicsInit');
  $('body').on('iasRender', function () {
    $('.js-lazy-pic').unveil(200);
  });
});

$(function () {
  if ($('.catalogue-page__layout').length) {
    if (!$('.js-toggle-catalogue-filter').length) {
      $('.catalogue-page__content').addClass('catalogue-page__content--without-filters');
      $('body').removeClass('filter-is-shown');
    }
  }
});

// Catalog product color toggler

$(function () {
  $('body').on('click', '.js-catalog-product-color', function (e) {
    e.preventDefault();

    var self = $(this);
    var url = self.data('href');

    $.mouseLoader(true);

    $.post(url, {}, function (response) {
      self.closest('div.js-product').html($(response).html());
      $(document).trigger('lazyPicsInit');
      $.mouseLoader(false);
    });
  });
});

},{}],5:[function(require,module,exports){
'use strict';

(function () {

  if (!$('.compare-table').length) return;

  var $left = void 0;
  var $right = void 0;

  $('.compare-body').each(function () {
    var $this = $(this);
    var ps = new PerfectScrollbar('#' + $this.attr('id'));
    $this.data('psInstance', ps);
  });

  $(function () {
    $(window).on('resize compareInit tabChanged fontsLoaded', function () {
      $left = $('.tabs-panel.is-active .compare-header');
      $right = $('.tabs-panel.is-active .compare-body');
      equalizeRows();
      if ($right.data('psInstance')) {
        $right.data('psInstance').update();
      } else {
        var ps = new PerfectScrollbar('#' + $right.attr('id'));
        $right.data('psInstance', ps);
      }
    }).trigger('compareInit');
  });

  function equalizeRows() {
    $left.find('tr').each(function () {
      var $headRow = $(this);
      var rowIndex = $headRow.index();
      var $bodyRow = $right.find('tr:not(.compare-page__mobile-param-label):eq(' + rowIndex + ')');
      var headRowHeight = $headRow.height();
      var bodyRowHeight = $bodyRow.height();
      var biggestHeight = Math.max(headRowHeight, bodyRowHeight);
      $headRow.add($bodyRow).height(biggestHeight);
    });
  }

  $('.js-compare-clear-prompt').click(function (e) {
    e.preventDefault();
    var $this = $(this);
    alertify.confirm('Вы уверены?', function (answer) {
      if (answer) {
        document.location = $this.attr('href');
      }
    });
  });
})();

},{}],6:[function(require,module,exports){
'use strict';

$('.js-toggle-catalogue-filter').click(function () {
  $('body').toggleClass('filter-is-shown');
  localStorage.setItem('FILTER_IS_SHOWN', $('body').hasClass('filter-is-shown'));
});

/*$('.js-toggle-filter-group').click(function() {
  $(this).parent().toggleClass('is-collapsed');
});*/

if ($('.catalogue-page__filter').length) {
  propagating(new Hammer(document.querySelector('.catalogue-page__filter'))).on('swipeleft', function () {
    $('body').removeClass('filter-is-shown');
  });
  if ($('.filter__slider').length) {
    propagating(new Hammer(document.querySelector('.filter__slider'))).on('swipeleft', function (e) {
      e.stopPropagation();
    });
  }
}

$(document).on('click', '.js-hide-filter', function (e) {
  e.preventDefault();
  $('body').removeClass('filter-is-shown');
});

$(document).on('click', '.js-toggle-filter-list', function (e) {
  e.preventDefault();
  $(this).parent().toggleClass('is-expanded');
});

},{}],7:[function(require,module,exports){
'use strict';

var _globals = require('./globals');

// Selectric
// ---------

_globals.$DOCUMENT.on('initSelectric yiiListViewUpdated afterReplaceWith', function () {
  $('select').each(function () {
    var $select = $(this);
    $select.selectric({
      disableOnMobile: false,
      nativeOnMobile: $select.hasClass('mobile-adapted') ? false : true,
      labelBuilder: function labelBuilder(itemData) {
        return colorOptionsBuilder(itemData);
      },
      optionsItemBuilder: function optionsItemBuilder(itemData, element, index) {
        return colorOptionsBuilder(itemData);
      }
    });
  });
}).trigger('initSelectric');

function colorOptionsBuilder(item) {
  var $option = $(item.element);
  var $select = $option.closest('select');
  if (!$select.hasClass('with-icons') || $option.prop('disabled')) return item.text;
  var colorsList = $option.data('color').split(',');
  var $colorIcon = $('<span class="icon"></span>');
  colorsList.forEach(function (color) {
    $colorIcon.append($('<span style="background: ' + color + '"></span>'));
  });
  return $colorIcon[0].outerHTML + ' <span class="wrapped--text">' + item.text + '</span>';
}

// Checkboxes
// ----------

_globals.$BODY.on('change initCheckboxes', '.js-checkbox input', function (event) {
  var $inp = $(event.target);
  var $label = $inp.closest('.js-checkbox');

  if ($inp.prop('checked')) {
    $label.addClass('is-checked');
  } else {
    $label.removeClass('is-checked');
  }
});
$('.js-checkbox input').trigger('initCheckboxes');

// Radio buttons
// -------------

_globals.$BODY.on('change initRadio', '.js-radio input', function (event) {
  var $inp = $(event.target);
  var $group = $('[name="' + $inp.attr('name') + '"]');
  var $labels = $group.closest('.js-radio');
  var $selected_item = $labels.find('input').filter(':checked').closest('.js-radio');

  $labels.removeClass('is-checked');
  $selected_item.addClass('is-checked');
});
$('.js-radio input').trigger('initRadio');

// Textarea autosize
// -----------------

$(function () {
  autosize($('textarea'));
});

// Fileinputs
// ----------

_globals.$BODY.on('change', '.js-fileinput input', function (event) {
  $(event.target).closest('.js-fileinput').parent().find('.js-fileinput-filename').text($(this).val());
});

// Sliders
// -------

/*$(() => {

  $('.js-price-slider').each(function() {
    const $slider = $(this);

    $slider.slider({
      range: true,
      min: 5000,
      max: 300000,
      values: [19460, 90930],
      create: function() {
        $slider.find('.ui-slider-handle:first').html($('<span>' + $(this).slider('values')[0].toLocaleString() + ' <span class="currency"><span>руб.</span></span></span>'));
        $slider.find('.ui-slider-handle:last').html($('<span>' + $(this).slider('values')[1].toLocaleString() + ' <span class="currency"><span>руб.</span></span></span>'));
      },
      slide: function(event, ui) {
        $(ui.handle).html($('<span>' + ui.value.toLocaleString() + '<span class="currency"><span>руб.</span></span></span>'));
      },
    });
  });

});*/

// Touched inputs state
// --------------------

$('.form-field').find('.inp, .textarea').on('focus blur inputs-init input', function () {
  var $this = $(this);
  var value = $this.val();

  if (value != '' && value != null) {
    $this.closest('.form-field').addClass('is-touched');
  } else {
    $this.closest('.form-field').removeClass('is-touched');
  }
}).trigger('inputs-init');

},{"./globals":9}],8:[function(require,module,exports){
"use strict";

},{}],9:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TOSRUS_DEFAULTS = exports.$BODY = exports.$HTML = exports.$DOCUMENT = exports.$WINDOW = exports.HEADER_HEIGHT = exports.NOTEBOOK_WIDTH = exports.SMALL_NOTEBOOK_WIDTH = exports.TABLET_WIDTH = exports.PORTRAIT_TABLET_WIDTH = exports.LANDSCAPE_MOBILE_WIDTH = exports.MOBILE_WIDTH = exports.SMALL_MOBILE_WIDTH = exports.IS_TOUCH_DEVICE = exports.IS_IE = exports.IS_IOS = exports.IS_DESKTOP = exports.IS_MOBILE = undefined;

var _mediaQueriesConfig = require('../media-queries-config.json');

var _mediaQueriesConfig2 = _interopRequireDefault(_mediaQueriesConfig);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

// Const
// -----

var IS_MOBILE = exports.IS_MOBILE = navigator.userAgent.match(/(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i) !== null;
var IS_DESKTOP = exports.IS_DESKTOP = !IS_MOBILE;
var IS_IOS = exports.IS_IOS = navigator.userAgent.match(/(iPad|iPhone|iPod)/g) ? true : false;
var IS_IE = exports.IS_IE = navigator.appVersion.indexOf("MSIE") !== -1 || navigator.userAgent.match(/Trident.*rv[ :]*11\./);
var IS_TOUCH_DEVICE = exports.IS_TOUCH_DEVICE = 'ontouchstart' in document;

var HTML = document.documentElement;

HTML.classList.remove('no-js');

if (IS_MOBILE) HTML.classList.add('is-mobile');
if (IS_DESKTOP) HTML.classList.add('is-desktop');
if (IS_IOS) HTML.classList.add('is-ios');
if (IS_IE) HTML.classList.add('is-ie');
if (IS_TOUCH_DEVICE) HTML.classList.add('is-touch-device');

var SMALL_MOBILE_WIDTH = exports.SMALL_MOBILE_WIDTH = _mediaQueriesConfig2.default.mobile.small;
var MOBILE_WIDTH = exports.MOBILE_WIDTH = _mediaQueriesConfig2.default.mobile.portrait;
var LANDSCAPE_MOBILE_WIDTH = exports.LANDSCAPE_MOBILE_WIDTH = _mediaQueriesConfig2.default.mobile.landscape;
var PORTRAIT_TABLET_WIDTH = exports.PORTRAIT_TABLET_WIDTH = _mediaQueriesConfig2.default.tablet.portrait;
var TABLET_WIDTH = exports.TABLET_WIDTH = _mediaQueriesConfig2.default.tablet.landscape;
var SMALL_NOTEBOOK_WIDTH = exports.SMALL_NOTEBOOK_WIDTH = _mediaQueriesConfig2.default.notebook.small;
var NOTEBOOK_WIDTH = exports.NOTEBOOK_WIDTH = _mediaQueriesConfig2.default.notebook.normal;

var HEADER_HEIGHT = exports.HEADER_HEIGHT = $('.header').height();

// Selectors
// ---------

var $WINDOW = exports.$WINDOW = $(window);
var $DOCUMENT = exports.$DOCUMENT = $(document);
var $HTML = exports.$HTML = $(document.documentElement);
var $BODY = exports.$BODY = $(document.body);

// Tosrus default settings
// -----------------------

var TOSRUS_DEFAULTS = exports.TOSRUS_DEFAULTS = {
  buttons: {
    next: true,
    prev: true
  },
  keys: {
    prev: 37,
    next: 39,
    close: 27
  },
  wrapper: {
    onClick: 'close'
  }
};

// Trigger fonts loaded event
// --------------------------

if (document.fonts != undefined) {
  document.fonts.onloadingdone = function () {
    $(document).trigger('fontsLoaded');
  };
}

// Custom vh for mobile devices
// ----------------------------

$(window).on('resize initVh', function () {
  var vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', vh + 'px');
}).trigger('initVh');

},{"../media-queries-config.json":2}],10:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.IS_SMALL_MOBILE_WIDTH = exports.IS_LANDSCAPE_MOBILE_WIDTH = exports.IS_MOBILE_WIDTH = exports.IS_TABLET_WIDTH = exports.IS_SMALL_NOTEBOOK_WIDTH = exports.IS_NOTEBOOK_WIDTH = exports.IS_DESKTOP_WIDTH = exports.WINDOW_HEIGHT = exports.WINDOW_WIDTH = undefined;
exports.status = status;

var _globals = require('./globals');

var WINDOW_WIDTH = exports.WINDOW_WIDTH = window.innerWidth || _globals.$WINDOW.width();
var WINDOW_HEIGHT = exports.WINDOW_HEIGHT = _globals.$WINDOW.height();
_globals.$WINDOW.resize(function () {
  exports.WINDOW_WIDTH = WINDOW_WIDTH = window.innerWidth || _globals.$WINDOW.width();
  exports.WINDOW_HEIGHT = WINDOW_HEIGHT = _globals.$WINDOW.height();
});

var IS_DESKTOP_WIDTH = exports.IS_DESKTOP_WIDTH = function IS_DESKTOP_WIDTH() {
  return WINDOW_WIDTH > _globals.NOTEBOOK_WIDTH;
};
var IS_NOTEBOOK_WIDTH = exports.IS_NOTEBOOK_WIDTH = function IS_NOTEBOOK_WIDTH() {
  return WINDOW_WIDTH > _globals.SMALL_NOTEBOOK_WIDTH && WINDOW_WIDTH <= _globals.NOTEBOOK_WIDTH;
};
var IS_SMALL_NOTEBOOK_WIDTH = exports.IS_SMALL_NOTEBOOK_WIDTH = function IS_SMALL_NOTEBOOK_WIDTH() {
  return WINDOW_WIDTH > _globals.TABLET_WIDTH && WINDOW_WIDTH <= _globals.SMALL_NOTEBOOK_WIDTH;
};
var IS_TABLET_WIDTH = exports.IS_TABLET_WIDTH = function IS_TABLET_WIDTH() {
  return WINDOW_WIDTH >= _globals.PORTRAIT_TABLET_WIDTH && WINDOW_WIDTH <= _globals.TABLET_WIDTH;
};
var IS_MOBILE_WIDTH = exports.IS_MOBILE_WIDTH = function IS_MOBILE_WIDTH() {
  return WINDOW_WIDTH <= _globals.MOBILE_WIDTH;
};
var IS_LANDSCAPE_MOBILE_WIDTH = exports.IS_LANDSCAPE_MOBILE_WIDTH = function IS_LANDSCAPE_MOBILE_WIDTH() {
  return WINDOW_WIDTH <= _globals.LANDSCAPE_MOBILE_WIDTH;
};
var IS_SMALL_MOBILE_WIDTH = exports.IS_SMALL_MOBILE_WIDTH = function IS_SMALL_MOBILE_WIDTH() {
  return WINDOW_WIDTH <= _globals.SMALL_MOBILE_WIDTH;
};

// Response status
function status(response) {
  if (!response.ok) {
    throw new Error(response.statusText);
  }
  return response;
}

},{"./globals":9}],11:[function(require,module,exports){
'use strict';

document.addEventListener('DOMContentLoaded', function () {
  var lazyloadImages = void 0;

  if ('IntersectionObserver' in window) {
    lazyloadImages = document.querySelectorAll('.js-lazy-bg');
    var imageObserver = new IntersectionObserver(function (entries, observer) {
      entries.forEach(function (entry) {
        if (entry.isIntersecting) {
          var image = entry.target;
          image.classList.remove('js-lazy-bg');
          imageObserver.unobserve(image);
        }
      });
    });

    lazyloadImages.forEach(function (image) {
      imageObserver.observe(image);
    });
  } else {
    var lazyload = function lazyload() {
      if (lazyloadThrottleTimeout) {
        clearTimeout(lazyloadThrottleTimeout);
      }

      lazyloadThrottleTimeout = setTimeout(function () {
        var scrollTop = window.pageYOffset;
        lazyloadImages.forEach(function (img) {
          if (img.offsetTop < window.innerHeight + scrollTop) {
            img.src = img.dataset.src;
            img.classList.remove('lazy');
          }
        });
        if (lazyloadImages.length == 0) {
          document.removeEventListener('scroll', lazyload);
          window.removeEventListener('resize', lazyload);
          window.removeEventListener('orientationChange', lazyload);
        }
      }, 20);
    };

    var lazyloadThrottleTimeout = void 0;
    lazyloadImages = document.querySelectorAll('.lazy');

    document.addEventListener('scroll', lazyload);
    window.addEventListener('resize', lazyload);
    window.addEventListener('orientationChange', lazyload);
  }
});

},{}],12:[function(require,module,exports){
'use strict';

(function () {

  $('input[type="tel"]').each(function () {

    var $input = $(this);
    var defaultPlaceholder = $input.attr('placeholder');
    var defaultPlaceholderMask = '+7 (___) ___-__-__';
    var phoneMask = '+7 (000) 000-00-00';
    var pasted = false;

    $input.on('focus', function () {
      $input.attr('placeholder', defaultPlaceholderMask);
    }).on('blur', function () {
      $input.attr('placeholder', defaultPlaceholder);
    });

    $input.on('paste drop', function () {
      var isEmpty = !$input.val();
      var element = $input.get(0);
      var selectionLength = element.selectionEnd - element.selectionStart;

      if (!isEmpty && $input.val().length !== selectionLength) {
        return;
      }

      pasted = true;
    });

    $input.on('input', function (e) {
      var value = $input.val();

      if (value.startsWith('8')) {
        if (pasted && value.length !== 11) {
          pasted = false;
          return;
        }

        pasted = false;

        e.stopPropagation();

        value = value.replace('8', '+7');
        $input.val(value);
      }
    });

    $input.mask(phoneMask);
  });
})();

},{}],13:[function(require,module,exports){
'use strict';

var _globals = require('./globals');

var $menus = $('.js-menu');

if (!_globals.IS_TOUCH_DEVICE) {
  $menus.on('mouseenter.js-menu', 'li', function () {
    var $this = $(this);

    clearTimeout($this.data('unhoverTimeout'));

    if ($this.hasClass('has-dropdown')) {
      $this.data('hoverTimeout', setTimeout(function () {
        $this.addClass('is-hovered');
        fixMenuFlexWidth($this);
        fixMenuFlexWidth($this); // второй проход тут не просто так
        if ($this.hasClass('js-equalize-menu-columns')) {
          var maxHeight = Math.max($this.closest('ul').css('height', '').height(), $this.find('> ul').height());
          $this.closest('ul').css('height', maxHeight);
        }
      }, 200));
    } else {
      $this.addClass('is-hovered');
    }
  });

  $menus.on('mouseleave.js-menu', 'li', function () {
    var $this = $(this);

    clearTimeout($this.data('hoverTimeout'));

    $this.data('unhoverTimeout', setTimeout(function () {
      $this.removeClass('is-hovered');
      if ($this.hasClass('js-equalize-menu-columns')) {
        $this.closest('ul').css('height', '');
      }
    }, 200));
  });

  $menus.on('click.js-m-menu', 'a', function (e) {
    e.preventDefault();

    var $anchor = $(this);
    var $parent = $anchor.parent();
    var has_dropdown = $parent.hasClass('has-dropdown');
    var is_hovered = $parent.hasClass('is-hovered');
    var link = $anchor.attr('href');

    if (!has_dropdown) {
      location.href = $anchor.attr('href');
    } else {
      if (is_hovered && link) {
        location.href = link;
      }
    }
  });
}

if (_globals.IS_TOUCH_DEVICE) {
  $menus.on('click.js-m-menu', 'a', function (e) {
    e.preventDefault();

    var $anchor = $(this);
    var $parent = $anchor.parent();
    var has_dropdown = $parent.hasClass('has-dropdown');
    var is_hovered = $parent.hasClass('is-hovered');
    var link = $anchor.attr('href');

    $parent.siblings().removeClass('is-hovered');

    if (!has_dropdown) {
      location.href = $anchor.attr('href');
    } else {
      if (is_hovered && link) {
        location.href = link;
      } else {
        $parent.toggleClass('is-hovered');
        fixMenuFlexWidth($parent);
        fixMenuFlexWidth($parent);
        // setTimeout(() => { fixMenuFlexWidth($parent) }, 350);
      }
    }
  });
}

$('.js-toggle-mobile-menu-item').click(function (e) {
  e.preventDefault();
  e.stopPropagation();
  $(this).closest('.has-dropdown').toggleClass('is-hovered').siblings().removeClass('is-hovered');
});

function fixMenuFlexWidth($item) {
  $item.find('.js-fix-flex-width').each(function () {
    var $dropdown = $(this);
    var $lastItem = $dropdown.find('li:last');
    var fixedWidth = $lastItem.width() + $lastItem.position().left;
    var windowWidth = $(window).width();
    var dropdownOffset = $dropdown.offset().left;
    if (dropdownOffset + fixedWidth < windowWidth) {
      $dropdown.width(fixedWidth);
    } else {
      $dropdown.width(windowWidth - dropdownOffset);
    }
  });
}

var opened_menu_class = 'menu-is-opened';

$('.js-toggle-mobile-menu').click(function (e) {
  e.preventDefault();

  _globals.$BODY.toggleClass(opened_menu_class);

  if (_globals.$BODY.hasClass(opened_menu_class)) {
    _globals.$BODY.on('click.close-menu', function (e) {
      var $target = $(e.target);

      if ($target.closest('.site-aside').length || $target.hasClass('js-toggle-mobile-menu')) {
        if (!$target.hasClass('js-overlay')) {
          e.stopPropagation();
          return false;
        }
      }

      closeMenu();
    });
  } else {
    closeMenu();
  }
});

function closeMenu() {
  _globals.$BODY.removeClass(opened_menu_class).off('click.close-menu');
  $('.site-aside .is-hovered').removeClass('is-hovered');
}

Hammer(document.querySelector('.site-aside')).on('panleft', function (e) {
  if (e.pointerType == 'touch' && Math.abs(e.deltaY) > Math.abs(e.deltaX)) return false;
  closeMenu();
});

$('.js-toggle-menu-item').click(function (e) {
  e.preventDefault();
  e.stopPropagation();

  $(this).closest('.has-dropdown').toggleClass('is-hovered');
});

$('.js-mobile-search .btn').click(function () {
  $(this).closest('form').submit();
});

$('.js-toggle-footer-subscribe').click(function () {
  $(this).parent().toggleClass('is-active');
});

$('.js-toggle-mobile-search').click(function (e) {
  e.preventDefault();
  var $search = $('#mobile-header-search');
  $search.stop(true, true).fadeToggle();
  $search.find('input[type="search"]').focus();
});

$('.site-aside__links a').click(function () {
  if ($(this).closest('.has-dropdown').length) return;
  if (_globals.$BODY.hasClass(opened_menu_class)) {
    closeMenu();
  }
});

$('.site-aside__phone').click(function () {
  document.location.href = $(this).attr('href');
});

},{"./globals":9}],14:[function(require,module,exports){
'use strict';

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _globals = require('./globals');

var OVERLAY_LOADER_DEFAULTS = {
  hideSelector: '.js-close-popup',
  fixedElements: '.js-fixed-element'

  // Open popup
};_globals.$DOCUMENT.on('click.overlay-open', '.js-overlay', function (e) {
  e.preventDefault();

  var $popup = $(this).attr('href');

  $.overlayLoader(true, _extends({
    node: $popup
  }, OVERLAY_LOADER_DEFAULTS));
});

_globals.$DOCUMENT.on('click.overlay-open', '.js-ajax-overlay', function (e) {
  e.preventDefault();

  var $self = $(this);
  var $node = $($self.attr('href'));

  $.overlayLoader(true, _extends({
    node: $node
  }, OVERLAY_LOADER_DEFAULTS));

  $.ajax({
    type: $self.data('type'),
    url: $self.data('ajax-url'),
    data: _parseAjaxOverlayParams($self.data('ajax-params')),
    timeout: 10000,
    success: function success(data) {
      $node.find('.js-ajax-data').html(data);
      window.initPriceApp('#js-card-price-app-details');
      $(document).trigger('ajaxBlockLoaded');
    },
    error: function error(data) {}
  });
});

_globals.$DOCUMENT.on('ajaxBlockLoaded', function () {
  _globals.$DOCUMENT.trigger('initQuickViewSliders');
});

function _parseAjaxOverlayParams(str) {

  if (str == undefined || str == '') return {};

  var obj = {};
  var array = str.split('&');

  array.forEach(function (value) {
    var _a = value.split('=');
    obj[_a[0]] = _a[1];
  });

  return obj;
}

// Autofocus
_globals.$DOCUMENT.on('overlayLoaderShow', function (e, $node) {
  $node.find('.js-autofocus-inp').focus();
});

// Submit typo popup
_globals.$DOCUMENT.on('keydown', function (e) {
  if (e.ctrlKey && e.keyCode == 13) {
    if (window.getSelection) {
      var selectedText = window.getSelection().toString();
      $('.js-submit-typo-content').val(selectedText);
    }
    $.overlayLoader(true, _extends({
      node: $('#submit-typo-popup')
    }, OVERLAY_LOADER_DEFAULTS));
  }
});

},{"./globals":9}],15:[function(require,module,exports){
'use strict';

var _globals = require('./globals');

var _helpers = require('./helpers');

var $order_aside = $('.js-order-fixed-panel');

if ($order_aside.length && !(0, _helpers.IS_MOBILE_WIDTH)()) {
  var PANEL_OFFSET_TOP = 25;
  var PANEL_OFFSET_BOTTOM = 0;
  var $container = $('.order-page__layout');
  var aside_height = $order_aside.outerHeight() + PANEL_OFFSET_BOTTOM;
  var aside_position = $order_aside.offset().top;
  var bottom_limit = $container.offset().top + $container.height();
  var max_offset = bottom_limit - aside_height;

  _globals.$WINDOW.on('scroll.order-page-scroll', function () {
    var scroll_top = _globals.$WINDOW.scrollTop();
    var offset = scroll_top - aside_position + PANEL_OFFSET_TOP;

    if (offset < 0) {
      $order_aside.css('top', 0);
    } else if (offset >= 0) {
      if (scroll_top < max_offset - PANEL_OFFSET_TOP) {
        $order_aside.css('top', offset);
      } else {
        $order_aside.css('top', max_offset - aside_position);
      }
    }
  });
}

},{"./globals":9,"./helpers":10}],16:[function(require,module,exports){
'use strict';

var _globals = require('./globals');

var _helpers = require('./helpers');

// Scroll to
// ---------

_globals.$DOCUMENT.on('click.scroll-to', '.js-scroll-to', function (e) {
  e.preventDefault();

  var $lnk = $(this);
  var $elem_to_scroll = $($lnk.attr('href'));
  var speed = $lnk.data('speed') || 150;
  var offset = $lnk.data('offset') || 0;

  _globals.$WINDOW.scrollTo($elem_to_scroll, { duration: speed, offset: offset });
});

// Scrolling to top
// ----------------

if (!(0, _helpers.IS_MOBILE_WIDTH)()) {
  var $go_top_btn = $('<div class="go-top-btn"></div>');

  $go_top_btn.click(function () {
    _globals.$WINDOW.scrollTo(0, 200);
  });

  _globals.$WINDOW.scroll(function () {
    var scroll_top = _globals.$WINDOW.scrollTop();
    if (scroll_top > 0) {
      $go_top_btn.addClass('is-visible');
    } else {
      $go_top_btn.removeClass('is-visible');
    }
  });

  _globals.$BODY.append($go_top_btn);
}

// Scrolled state
// --------------

_globals.$WINDOW.on('scroll initScrollState', function () {
  if (_globals.$WINDOW.scrollTop() > 0) {
    _globals.$BODY.addClass('is-scrolled');
  } else {
    _globals.$BODY.removeClass('is-scrolled');
  }
}).trigger('initScrollState');

},{"./globals":9,"./helpers":10}],17:[function(require,module,exports){
'use strict';

$(document).on('click', '.js-toggle-share-block', function () {
  var $link = $(this);
  var $shareBlock = $($link.data('share-block'));

  $link.toggleClass('is-active');
  $shareBlock.stop(true, true).fadeToggle();

  if ($link.hasClass('is-active')) {
    $(document).on('click.toggleShare', function (e) {
      var $target = $(e.target);

      if ($target.closest('.ya-share2').length) {
        e.stopPropagation();
        return false;
      }

      $shareBlock.stop(true, true).fadeOut();
      $link.removeClass('is-active');
      $(document).off('click.toggleShare');
    });
  } else {
    $(document).off('click.toggleShare');
  }
});

},{}],18:[function(require,module,exports){
'use strict';

var _globals = require('./globals');

var _helpers = require('./helpers');

$('.js-slideshow').each(function () {
  var $this = $(this);

  $this.slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 5000,
    dots: true,
    arrows: true,
    pauseOnHover: false
  });
});

$('.js-vitrine-slider').each(function () {
  var $this = $(this);
  var $parent = $this.parent();

  $this.slick({
    slidesToShow: 5,
    slidesToScroll: 1,
    infinite: false,
    arrows: true,
    appendArrows: $parent,
    responsive: [{
      breakpoint: _globals.NOTEBOOK_WIDTH + 1,
      settings: {
        slidesToShow: 4
      }
    }, {
      breakpoint: _globals.PORTRAIT_TABLET_WIDTH + 1,
      settings: {
        slidesToShow: 3
      }
    }, {
      breakpoint: _globals.SMALL_MOBILE_WIDTH + 1,
      settings: {
        slidesToShow: 1
      }
    }]
  });
});

$(window).on('sliders-init resize tabChanged', function () {

  $('.js-accessories-slider').each(function () {
    var $this = $(this);
    var $parent = $this.parent();

    if (!$this.is(':visible')) return;

    if ($this.hasClass('slick-initialized') && $(window).width() <= 1140) {
      $this.slick('unslick');
    }

    if ($(window).width() <= 1140) {
      return;
    }

    if ($this.hasClass('slick-initialized') && $this.find('.slick-slide').width() != 0) return;

    if ($this.find('.slick-slide').width() == 0) {
      $this.slick('unslick');
    }

    $this.slick({
      slidesToShow: 6,
      slidesToScroll: 6,
      infinite: false,
      arrows: true,
      appendArrows: $parent,
      responsive: [{
        breakpoint: 1601,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 5
        }
      }, {
        breakpoint: 1301,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4
        }
      }]
    });
  });
}).trigger('sliders-init');

$(function () {

  $('.js-main-image-slider').each(function () {
    var $this = $(this);

    $this.slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      infinite: false,
      arrows: false,
      asNavFor: '.js-gallery-slider',
      waitForAnimate: false,
      // lazyLoad: 'progressive',
      responsive: [{
        breakpoint: _globals.TABLET_WIDTH + 1,
        settings: {
          arrows: true,
          asNavFor: false
        }
      }]
    });
  });

  $('.js-gallery-slider').each(function () {
    var $this = $(this);
    var $parent = $this.parent();

    $this.slick({
      slidesToShow: 8,
      slidesToScroll: 1,
      infinite: false,
      arrows: true,
      appendArrows: $parent,
      asNavFor: '.js-main-image-slider',
      focusOnSelect: true,
      // lazyLoad: 'progressive',
      responsive: [{
        breakpoint: _globals.SMALL_MOBILE_WIDTH + 1,
        settings: {
          slidesToShow: 4
        }
      }]
    });
  });
});

$(document).on('initQuickViewSliders overlayLoaderShow', function () {
  $('.js-quick-view-image-slider').each(function () {
    var $this = $(this);

    if ($this.hasClass('slick-initialized') && $this.find('.slick-slide').width() != 0) return;

    if ($this.find('.slick-slide').width() == 0) {
      $this.slick('unslick');
    }

    $this.slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      infinite: false,
      arrows: false,
      asNavFor: '.js-quick-view-thumbs-slider',
      responsive: [{
        breakpoint: _globals.TABLET_WIDTH + 1,
        settings: {
          arrows: true,
          asNavFor: false
        }
      }]
    });
  });

  $('.js-quick-view-thumbs-slider').each(function () {
    var $this = $(this);
    var $parent = $this.parent();

    if ($this.hasClass('slick-initialized') && $this.find('.slick-slide').width() != 0) return;

    if ($this.find('.slick-slide').width() == 0) {
      $this.slick('unslick');
    }

    $this.slick({
      slidesToShow: 8,
      slidesToScroll: 1,
      infinite: false,
      arrows: true,
      appendArrows: $parent,
      asNavFor: '.js-quick-view-image-slider',
      focusOnSelect: true,
      responsive: [{
        breakpoint: _globals.SMALL_MOBILE_WIDTH + 1,
        settings: {
          slidesToShow: 4
        }
      }]
    });
  });
}).trigger('initQuickViewSliders');

function categoriesSlidersInit() {
  $('.categories-vitrine:not(.categories-vitrine--sitemap)').each(function () {
    var $this = $(this);

    if ((0, _helpers.IS_LANDSCAPE_MOBILE_WIDTH)()) {
      if (!$this.data('slick')) {
        $this.data('slick', $this.slick({
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          arrows: false,
          dots: true,
          adaptiveHeight: true
        }));
      }
    } else {
      if ($this.data('slick')) {
        $this.slick('unslick');
        $this.data('slick', false);
      }
    }
  });
}

$(window).on('resize initCategoriesSliders', function () {
  categoriesSlidersInit();
}).trigger('initCategoriesSliders');

},{"./globals":9,"./helpers":10}],19:[function(require,module,exports){
'use strict';

var _globals = require('./globals');

_globals.$DOCUMENT.on('mousedown.js-spinner', '.js-spinner-down, .js-spinner-up', function () {
  var $spinner_control = $(this);
  var $input = $spinner_control.siblings('.inp');
  var step = $input.data('step') ? $input.data('step') : 1;
  var may_be_zero = $input.data('zero') ? $input.data('zero') : false;
  var value = parseInt($input.val(), 10);
  var inc_timeout = void 0,
      inc_interval = void 0,
      dec_timeout = void 0,
      dec_interval = void 0;

  $spinner_control.on('mouseup.js-spinner', clearAll).on('mouseleave.js-spinner', $spinner_control, clearAll);

  if ($spinner_control.hasClass('js-spinner-down')) {
    decVal();dec_timeout = setTimeout(function () {
      dec_interval = setInterval(decVal, 70);
    }, 300);
  }

  if ($spinner_control.hasClass('js-spinner-up')) {
    incVal();inc_timeout = setTimeout(function () {
      inc_interval = setInterval(incVal, 70);
    }, 300);
  }

  function incVal() {
    if ($.isMouseLoaderActive()) return;

    value = value + step;
    $input.val(value).change();
  }

  function decVal() {
    if ($.isMouseLoaderActive()) return;

    if (may_be_zero) {
      if (value >= step) {
        value = value - step;
        $input.val(value).change();
      }
    } else {
      if (value > step) {
        value = value - step;
        $input.val(value).change();
      }
    }
  }

  function clearAll() {
    clearTimeout(dec_timeout);clearInterval(dec_interval);
    clearTimeout(inc_timeout);clearInterval(inc_interval);
  }
});

_globals.$DOCUMENT.on('keydown', '.js-spinner .inp', function (e) {
  var $input = $(this);

  if (e.keyCode == 46 || e.keyCode == 8 || e.keyCode == 9 || e.keyCode == 27 || e.keyCode == 65 && e.ctrlKey === true || e.keyCode >= 35 && e.keyCode <= 39) {
    return;
  } else {
    if ((e.keyCode < 48 || e.keyCode > 57) && (e.keyCode < 96 || e.keyCode > 105)) {
      e.preventDefault();
    }
  }
});

_globals.$DOCUMENT.on('keyup paste', '.js-spinner .inp', function (e) {
  var $input = $(this);
  var may_be_zero = $input.data('zero') ? $input.data('zero') : false;

  if ($input.val() == 0 || $input.val() == '') {
    if (!may_be_zero) {
      $input.val(1);
    } else {
      $input.val(0);
    }
  }
});

},{"./globals":9}],20:[function(require,module,exports){
'use strict';

var _helpers = require('./helpers');

$('.js-spoiler-anchor').click(function () {
  $(this).closest('.js-spoiler').toggleClass('is-collapsed is-expanded');
});

$('.js-mobile-spoiler-anchor').click(function () {
  if (!(0, _helpers.IS_LANDSCAPE_MOBILE_WIDTH)()) return;
  $(this).closest('.js-mobile-spoiler').toggleClass('is-collapsed');
});

$('.js-toggle-brands').click(function () {
  $(this).closest('.brands-section').toggleClass('is-expanded');
});

/*velosipedy*/

$('.js-show-more').on('click', function () {
  $('.js-show').toggleClass('show-more');
});

},{"./helpers":10}],21:[function(require,module,exports){
'use strict';

$(document).on('click', '.js-tabs .tabs-nav li a', function (e) {
  e.preventDefault();

  var $this = $(this);
  var $panel = $($this.attr('href'));

  $this.closest('li').addClass('is-active').siblings().removeClass('is-active');
  $panel.closest('.js-tabs').find('.tabs-panel').removeClass('is-active').hide();
  $panel.fadeIn().addClass('is-active');

  $(window).trigger('tabChanged');
  setTimeout(function () {
    $(window).trigger('lookup'); // images search trigger for unveil plugin
    $('.js-lazy-pic').unveil(200);
  }, 350);
});

$(function () {
  $('.js-tabs').each(function () {
    $(this).find('.tabs-nav li:first a').click();
  });
});

$('.js-toggle-vitrine-tab').click(function () {
  $(this).parent().toggleClass('is-expanded').find('.categories-vitrine')[0].slick.refresh();
});

$('body').on('click', '.js-show-similar-tab', function () {
  $('.product-details__section--similar').removeClass('is-collapsed');
});

},{}],22:[function(require,module,exports){
'use strict';

$('.js-fix-short-vitrine').each(function () {
  var $this = $(this);
  var teasersAmount = $this.find('.vitrine__teaser-tile').length;

  if (teasersAmount) {
    $this.addClass('has-' + teasersAmount + '-teasers');
  }
});

$(document).on('click', '.js-toggle-gifts', function (e) {
  e.preventDefault();
  e.stopPropagation();
  var $this = $(this);
  var $product = $this.closest('.product');
  if (!$product.length) {
    $product = $this.closest('.quick-view');
  }
  $product.find('.product__gifts-block').stop(true, true).fadeToggle();
});

},{}],23:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
/**
 * Пирмер подключения в шаблоне:
 */

/**
 *
 * @type {{props: {offer: {type: ObjectConstructor}, defaultDump: {type: *[]}, dumpList: {type: ArrayConstructor}, classList: {type: ObjectConstructor}}, methods: {getClass(): *}, computed: {label(): *, dump(): *}}}
 */
var DumpComponent = {
  props: {
    offer: {
      type: Object
    },
    defaultDump: {
      type: [Number]
    },
    dumpList: {
      type: Object
    },
    classList: {
      type: Object
    }
  },
  methods: {
    getClass: function getClass() {
      return this.classList[this.dump];
    }
  },
  computed: {
    label: function label() {
      return this.dumpList[this.dump].name;
    },
    dump: function dump() {
      return this.offer.dump !== undefined ? this.offer.dump : this.defaultDump;
    }
  }
};

exports.default = DumpComponent;

},{}],24:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
/**
 * Пирмер подключения в шаблоне:
 * <?php $offers = $model->getOffersWithParameter(false) ?>
 * <offers-list-component :offers-list='<?=json_encode($offers)?>' inline-template>
 *  <div>
 *    <label class="item js-radio" v-bind:class="getClass(offer)" v-bind:data-size="offer.text"
 *           v-for="offer in offersList" @click="click(offer)">{{offer.text}}</label>
 *  </div>
 * </offers-list-component>
 */

/**
 * @type {{props: {selectedId: {type: NumberConstructor}, offersList: {type: ArrayConstructor}}, methods: {isActive(*): *}}}
 */
var OffersListComponent = {
  props: {
    selectedId: {
      type: Number
    },
    offersList: {
      type: Array
    },
    selectedClass: {
      type: String,
      default: 'is-checked'
    },
    disabledClass: {
      type: String,
      default: 'disabled'
    }
  },
  methods: {
    isActive: function isActive(id) {
      return this.$root.offer.id == id;
    },
    click: function click(offer) {

      if (offer.disabled) return;

      this.$root.offer = offer;
    },
    getClass: function getClass(offer) {
      var classArray = [];

      if (this.isActive(offer.id)) classArray.push(this.selectedClass);

      if (offer.disabled) classArray.push(this.disabledClass);

      return classArray.join(' ');
    }
  }
};

exports.default = OffersListComponent;

},{}],25:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
/**
 * Пример подключения в шаблоне
 * <price-component :price="this.offer.price" :old-price="this.offer.oldPrice" inline-template>
 * <div class="product-card__price-group">
 *   <div class="product-card__old-price" v-if="oldPrice">
 *     <span v-html="oldPriceFormatted"></span>
 *     <div class="product-card__discount" v-html="economyPercentFormatted"></div>
 *   </div>
 *   <div class="product-card__price" v-html="priceFormatted"><?php echo PriceHelper::price($model->getPrice(), ' <span class="currency"><span>₽</span></span>');?></div>
 * </div>
 * </price-component>
 */

/**
 * @type {{props: {price: {type: NumberConstructor}, oldPrice: {type: NumberConstructor, default: number}, currencySuffix: {type: StringConstructor, default: string}, emptyPriceText: {type: StringConstructor, default: string}}, computed: {economyPercent(): (number|*), priceFormatted(): *, oldPriceFormatted(): *, economyPercentFormatted(): string}}}
 */
var PriceComponent = {
  props: {
    price: {
      type: Number
    },
    oldPrice: {
      type: Number,
      default: 0
    },
    currencySuffix: {
      type: String,
      default: ' <span class="currency"><span>₽</span></span>'
    },
    emptyPriceText: {
      type: String,
      default: ''
    }
  },
  computed: {
    economyPercent: function economyPercent() {
      if (!this.oldPrice) return 0;
      if (this.price > this.oldPrice) return 0;
      var percent = (this.oldPrice - this.price) * 100 / this.oldPrice;
      return Math.ceil(percent);
    },
    priceFormatted: function priceFormatted() {
      if (this.price > 0) return this.price.toLocaleString() + this.currencySuffix;

      return this.emptyPriceText;
    },
    oldPriceFormatted: function oldPriceFormatted() {
      if (this.oldPrice > 0 && this.oldPrice > this.price) return this.oldPrice.toLocaleString() + this.currencySuffix;

      return this.emptyPriceText;
    },
    economyPercentFormatted: function economyPercentFormatted() {
      return this.economyPercent + '%';
    }
  }
};

exports.default = PriceComponent;

},{}]},{},[1]);
